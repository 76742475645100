<template>
  <v-container>
    <h1 class="display-1">Mes Profile</h1>
    <v-divider class="mt-3"></v-divider>
    <div class="mt-10">
      <v-row no-gutters v-for="item in formText" :key="item.id">
        <v-col cols="12" sm="5">
          <v-subheader class="pa-0 subtitle-1 font-weight-bold">{{
            item.title
          }}</v-subheader>
        </v-col>
        <v-col cols="12" sm="7" v-if="item.id === 3">
          <v-menu
            v-model="dateMenu"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="item.value"
                dense
                append-icon="mdi-calendar"
                outlined
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              color="primary"
              v-model="item.value"
              @input="dateMenu = false"
              locale="fr"
            ></v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="12" sm="7" v-else-if="item.id === 2">
          <v-text-field
            outlined
            dense
            v-model="item.value"
            readonly
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="7" v-else>
          <v-text-field outlined dense v-model="item.value"></v-text-field>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import { mapState } from "vuex"

export default {
  data() {
    return {
      dateMenu: false,
      formText: [
        {
          id: 0,
          title: "First Name",
          value: "",
        },
        {
          id: 1,
          title: "Last Name",
          value: "",
        },
        {
          id: 2,
          title: "Email",
          value: "",
        },
        {
          id: 3,
          title: "Date of Birth",
          value: "",
        },
        {
          id: 4,
          title: "Address",
          value: "",
        },
        {
          id: 5,
          title: "Phone Number",
          value: "",
        },
      ],
    };
  },

  computed: {
    ...mapState({
      loggedInCustomer: state => state.loggedInCustomer
    })
  },

  created() {
    this.formText = [
        {
          id: 0,
          title: "First Name",
          value: this.loggedInCustomer.firstName,
        },
        {
          id: 1,
          title: "Last Name",
          value: this.loggedInCustomer.lastName,
        },
        {
          id: 2,
          title: "Email",
          value: this.loggedInCustomer.email,
        },
        {
          id: 3,
          title: "Date of Birth",
          value: this.loggedInCustomer.dob,
        },
        {
          id: 4,
          title: "Address",
          value: this.loggedInCustomer.address,
        },
        {
          id: 5,
          title: "Phone Number",
          value: this.loggedInCustomer.phoneNumber,
        },
      ]
  }


};
</script>

<style></style>
